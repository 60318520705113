<template>
  <div id="app">
    <el-page-header @back="goBack" :content="deviceOfRow.typeName" class="header"></el-page-header>
    <!-- 视频容器 -->
    <div class="container">
      <div>
        <div class="timeShow">{{time}}</div>
      </div>

      <div class="video-content" v-if="!deviceOfRow.isBindYSVideo">
        <video id="videoElement" controls autoplay muted width="100%" height="100%"></video>
      </div>
      <div v-for="(item,index) in 4" class="videos-content" v-else>
        <video :id="'videoElement'+index" controls autoplay muted width="100%" height="100%"></video>
      </div>
    </div>
  </div>
</template>

<script>
import getVideoByDeviceId from '@/api/videoApi/getVideoByDeviceId'

import flvjs from 'flv.js/dist/flv.min.js'

export default {
  components: {
  },
  data() {
    return {
      time: '',
      videoList: [],
      YSVideo: '',
      deviceOfRow: {},
      flvPlayerList: []
    }
  },

  created() {
    this.deviceOfRow = this.$route.query.rowInfo;
    console.log(this.deviceOfRow);
    //   时间显示
    this.time = this.$moment().format("LLLL");
    setInterval(() => {
      this.time = this.$moment().format("LLLL");
    }, 1000);

  },

  mounted() {
    this.createVideo();
  },
  methods: {
    goBack() {
      this.videoHandleClose();
      this.$router.go(-1);
    },

    // 4个播放视频实例
    getVideos(i) {
      let flvPlayerObj = null;
      if (flvjs.isSupported()) { //判断当前浏览器是否支持播放
        flvPlayerObj = document.getElementById('videoElement' + i)
        console.log(flvPlayerObj);
        this.flvPlayer = flvjs.createPlayer({ //创建一个播放实例
          type: 'flv', //媒体类型
          isLive: true, //是否是直播流
          hasAudio: false, //是否有音频
          hasVideo: true, //是否有视频
          // url: 'https://mister-ben.github.io/videojs-flvjs/bbb.flv'
          url: this.videoList[i]
        });
        this.flvPlayer.attachMediaElement(flvPlayerObj); //将播放实例注册到video节点
        this.flvPlayer.load();
        this.flvPlayer.play();
        this.flvPlayerList.push(this.flvPlayer);
      }
    },

    // 1播放视频实例
    getVideo() {
      let flvPlayerObj = null;
      if (flvjs.isSupported()) { //判断当前浏览器是否支持播放
        flvPlayerObj = document.getElementById('videoElement')
        this.flvPlayer = flvjs.createPlayer({ //创建一个播放实例
          type: 'flv', //媒体类型
          isLive: true, //是否是直播流
          hasAudio: false, //是否有音频
          hasVideo: true, //是否有视频
          url: this.YSVideo
        });
        this.flvPlayer.attachMediaElement(flvPlayerObj); //将播放实例注册到video节点
        this.flvPlayer.load();
        this.flvPlayer.play();
        this.flvPlayerList.push(this.flvPlayer);
      }
    },

    async createVideo() {
      console.log(this.deviceOfRow);


      if (this.deviceOfRow.typeId == "YSVideo") {
        const res = await getVideoByDeviceId.getVideoInfo(this.deviceOfRow.id)
        console.log(res);
        this.YSVideo = res.data.data.YSVideo
        this.getVideo()
      } else {
        const res = await getVideoByDeviceId.getVideosByDeviceId(this.deviceOfRow.id)
        console.log(res);
        this.videoList = res.data.data.YSVideo;
        for (let i = 0; i < this.videoList.length; i++) {
          this.getVideos(i);
        }
      }
      // if (videos.message == "此设备绑定的萤石云账号有误") {
      //     this.$message.error("此设备绑定的萤石云账号有误")
      // } else {
      //   this.videoList = videos.data.YSVideo
      //   for (let i = 0; i < this.videoList.length; i++) {
      //     this.getVideos(i)
      //   }
      // }

      // console.log(this.deviceOfRow.id);
      // const res = await getVideoByDeviceId.getVideoInfo(this.deviceOfRow.id)
      // console.log(res);

      // if (videos.message == "此设备绑定的萤石云账号有误") {
      //   //   this.$message.error("此设备绑定的萤石云账号有误")
      // } else {
      //   this.YSVideo = video1.data.YSVideo
      //   this.getVideo()
      // }
    },

    videoHandleClose(done) {
				console.log(this.flvPlayerList);
				this.flvPlayerList.forEach(item => {
					item.pause();
					item.unload();
					item.detachMediaElement();
					item.destroy();
					item = null;
				});
				this.flvPlayerList = [];
				// done();
        console.log("销毁了");
			},
  }
}

</script>

<style lang="scss" scoped>
.goback {
  cursor: pointer;
}
.el-page-header {
  height: 70px;
  line-height: 70px;
  color: aliceblue;
  padding-left: 20px;
  background: #353535;
}
/deep/.el-page-header__content {
  color: aliceblue;
  font-size: 24px;
  font-weight: 600;
}
// 页头
/deep/.el-page-header__title,
.el-icon-back {
  font-size: 18px;
  font-weight: 600;
  color: aliceblue;
}

// 日期显示
.timeShow {
  height: 70px;
  line-height: 70px;
  text-align: right;
  position: absolute;
  padding-right: 30px;
  box-sizing: border-box;
  top: 0;
  right: 0;
  font-size: 26px;
  letter-spacing: 2px;
  font-weight: 600;
  color: aliceblue;
}
// ##################################

.header {
  width: 100%;
  position: fixed;
  top: 0;
}
.container {
  box-sizing: border-box;
  width: 100%;
  height: 92vh;
  background: #000;
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  justify-content: space-between;

  // 4个视频
  .videos-content {
    height: 46%;
    width: 50%;
    margin: 10px 0;
  }
  //1个视频
  .video-content {
    height: 90%;
    width: 90%;
    margin: 20px auto;
  }
}
//隐藏进度条
video::-webkit-media-controls-timeline {
  display: none;
}
//隐藏全屏按钮
// video::-webkit-media-controls-fullscreen-button {
//   display: none;
// }
//隐藏播放按钮
video::-webkit-media-controls-play-button {
  display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
</style>