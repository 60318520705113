import request from '@/../utils/request'
const api_name = '/iotechserver/WechatVideoController'

export default {

    // 根据设备ID获取设备信息
    getVideoInfo(deviceId) {
        return request({
            url: `${api_name}/getYSVideoInfo`,
            method: 'post',
            data: {
                id: deviceId,
            }
        })
    },


    // 根据设备ID获取设备视频地址
    getVideosByDeviceId(deviceId) {
        return request({
            url: `${api_name}/getYSVideosByDeviceId`,
            method: 'post',
            data: {
                id: deviceId,
            }
        })
    },
}